import React from "react";
import * as S from "../styles";
import { Tooltip } from "antd";
import { POOL_TYPES, STAGE_MODES, STAGE_TYPES } from "../../../constants";
import { isNonEmptyObject, poolStatusFormatter } from "../../../utilities";
import { PENDING_ADJUSTMENTS } from "../../../constants/status";
import { ErrorRed } from "../../../assets/svg";

const TagButtons = props => {
  const { activeSection, setActiveSection = () => {}, poolData = {}, selectedStage = {}, chatRequests = [] } = props; // Destructuring props with default values to avoid error
  const shouldShowBracketsButton =
    (isNonEmptyObject(selectedStage) && selectedStage.stageType === STAGE_TYPES.BRACKETS) ||
    poolData.poolType === POOL_TYPES.BRACKETS;

  const shouldShowLeaderboardButton =
    (isNonEmptyObject(selectedStage) && selectedStage.stageType === STAGE_TYPES.LEADERBOARD) ||
    poolData.poolType === POOL_TYPES.LEADERBOARD;

  return (
    <S.TagButtonContainer>
      <S.TagButton
        isActive={activeSection === "Players"}
        onClick={() => {
          setActiveSection("Players");
        }}
      >
        {poolData.joinMode === "TEAM" ? <span>Teams</span> : <span>Players</span>}
      </S.TagButton>
      {shouldShowBracketsButton ? (
        <S.TagButton
          isActive={activeSection === "Brackets"}
          onClick={() => {
            setActiveSection("Brackets");
          }}
        >
          Brackets
        </S.TagButton>
      ) : null}
      {shouldShowLeaderboardButton ? (
        <S.TagButton
          isActive={activeSection === "Leaderboard"}
          onClick={() => {
            setActiveSection("Leaderboard");
          }}
        >
          Leaderboard
        </S.TagButton>
      ) : null}
      <S.TagButton
        isActive={activeSection === "Invitational Codes"}
        onClick={() => {
          setActiveSection("Invitational Codes");
        }}
      >
        Invitational Codes
      </S.TagButton>
      <S.TagButton
        isActive={activeSection === "Info"}
        onClick={() => {
          setActiveSection("Info");
        }}
      >
        Info
      </S.TagButton>

      <S.TagButton
        isActive={activeSection === "Contact Info"}
        onClick={() => {
          setActiveSection("Contact Info");
        }}
      >
        Contact Info
      </S.TagButton>

      <S.TagButton
        isActive={activeSection === "Chatroom"}
        onClick={() => {
          setActiveSection("Chatroom");
        }}
      >
        {chatRequests.length > 0 ? <S.NotifCount>{chatRequests.length}</S.NotifCount> : null}
        Chatroom
      </S.TagButton>

      <div style={{ position: "relative" }}>
        {poolData?.poolStatus === PENDING_ADJUSTMENTS && (
          <ErrorRed style={{ position: "absolute", top: -25, left: 0, width: "17px" }} />
        )}
        <S.TagButton
          isActive={activeSection === "Changes Required"}
          onClick={() => {
            setActiveSection("Changes Required");
          }}
          disabled={poolData?.poolStatus !== PENDING_ADJUSTMENTS}
        >
          Changes Required
        </S.TagButton>
      </div>
    </S.TagButtonContainer>
  );
};

export default TagButtons;
