import React, { createContext, useContext, useEffect, useState } from "react";
import { useSession } from "./SessionProvider";
import { SocketContext } from "./SocketProvider";
import * as crypto from "crypto-js";
import { WSS_CRYPTO_SEED } from "../constants/env";

export const SocketAuthContext = createContext(null);

const SocketAuthProvider = ({ children }) => {
  const [isSocketAuthenticated, setIsSocketAuthenticated] = useState(false);

  const { session } = useSession();
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!session) return;
    const encryptedUserId = crypto.AES.encrypt(session?.user?.id, WSS_CRYPTO_SEED).toString();

    const encryptedSecToken = crypto.AES.encrypt(session?.access_token, WSS_CRYPTO_SEED).toString();

    const encryptedIp = crypto.AES.encrypt(session?.ip, WSS_CRYPTO_SEED).toString();

    const obj = { userId: encryptedUserId, secToken: encryptedSecToken, ipAddress: encryptedIp };

    socket.emit("authenticateAPUser", obj);
  }, [session?.user?.id]);

  useEffect(() => {
    socket.on("authenticateAPUserResponse", resp => {
      if (resp?.data?.authenticated) {
        setIsSocketAuthenticated(true);
      }
    });
  }, []);

  return (
    <SocketAuthContext.Provider value={{ isSocketAuthenticated, setIsSocketAuthenticated }}>
      {children}
    </SocketAuthContext.Provider>
  );
};

export default SocketAuthProvider;
