import React, { useEffect, useMemo, useState } from "react";

import * as S from "./styles";
import { Dropdown } from "../../common/Dropdown";
import { CreateBracketModal } from "./CreateBracketModal/CreateBracketModal";
import { executePoolAction, getPoolPlayerAndMatches } from "../../../api/pool";
import { useLogin } from "../../../providers/Loginprovider";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useGetSecHeaders } from "../../../hooks/useHeaders";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import { isNonEmptyObject } from "../../../utilities";
import MatchContainer from "./MatchContainer/MatchContainer";
import SwapModal from "./SwapModal/SwapModal";
import { BracketsDataContext } from "../../../providers/BracketsDataProvider";
import { swapBracketPlayers } from "../../../api/bracket";
import { notifyError, notifySuccess } from "../../common/ToastComponent";
import { getStageBracket } from "../../../api/stage";
import RoundSelectorDropdown from "./RoundSelectorDropdown";
import { RoundDateSwitchModal } from "../Brackets/RoundDateChangeModal";
import { Calendar } from "../../../assets/svg";
import moment from "moment";

function LoadingComponent() {
  return (
    <>
      {[...Array(4).keys()].map(() => (
        <S.LoadingRowsContainer>
          <LoadingSkeleton height="48px" />
          <LoadingSkeleton height="48px" />
        </S.LoadingRowsContainer>
      ))}
    </>
  );
}

function TableHeader() {
  return (
    <S.BracketDataHeader>
      <S.BracketDataHeaderText></S.BracketDataHeaderText>
      <S.BracketDataHeaderText>Nickname</S.BracketDataHeaderText>
      <S.BracketDataHeaderText>Results</S.BracketDataHeaderText>
      <S.BracketDataHeaderText>Round Status</S.BracketDataHeaderText>
      <S.BracketDataHeaderText>Actions</S.BracketDataHeaderText>
    </S.BracketDataHeader>
  );
}
const Brackets = (props = {}) => {
  const { gameDetails = {}, isFetchingPoolData, poolStatus, isMultiStagePool, stageId, joinMode, updateSelectedChatData } = props;
  const { orgIdState } = useLogin();
  const { poolId } = useParams();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();
  const [showBracketCreateModal, setShowBracketCreateModal] = useState(false);
  const [isCreateBracketsLoading, setIsCreateBracketsLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(1);
  const [selectedRound, setSelectedRound] = useState(1);
  const [roundsQuantity, setRoundsQuantity] = useState(0);
  const [openSwapModal, setOpenSwapModal] = useState(false);
  const [showRoundTimeModal, setShowRoundTimeModal] = useState(false);
  const [playerToSwap, setPlayerToSwap] = useState(null); // This will hold the player  data to swap, and also the data needed for the api call including match number and position

  const toggleRoundTimeModal = () => {
    setShowRoundTimeModal(!showRoundTimeModal);
  };

  const toggleBracketCreateModal = () => {
    setShowBracketCreateModal(!showBracketCreateModal);
  };

  // This API call gets the brackets detail of regular pools, as well as the leaders array (players list)
  const {
    data: { data: regularPoolBracketsData = {} } = {},
    isLoading: isLoadingPlayers,
    isFetching: isFetchingPlayers,
  } = useQuery({
    queryKey: ["players", orgIdState, poolId, selectedPage, selectedBranch, selectedRound, roundsQuantity],
    queryFn: () =>
      getPoolPlayerAndMatches(
        {
          poolId,
          organizationId: orgIdState,
          pageId: selectedPage,
          branchId: selectedBranch,
          roundId: roundsQuantity ? selectedRound : 0,
          stageId: stageId && isMultiStagePool ? stageId : undefined,
        },
        headers,
      ),
    enabled: !!headers?.encryptedHeader && !!orgIdState,
    refetchOnWindowFocus: false,
  });

  // This API call is used to get bracket details of stage brackets only
  const {
    data: { data: stageBracketsData = {} } = {},
    isLoading: isLoadingPlayersStageBracketsData,
    isFetching: isFetchingPlayersStageBracketsData,
  } = useQuery({
    queryKey: ["stage_brackets", orgIdState, poolId, selectedPage, selectedBranch, selectedRound, roundsQuantity],
    queryFn: () =>
      getStageBracket(
        {
          poolId,
          organizationId: orgIdState,
          pageId: selectedPage,
          branchId: selectedBranch,
          roundId: roundsQuantity ? selectedRound : 0,
          stageId: stageId && isMultiStagePool ? stageId : undefined,
        },
        headers,
      ),
    enabled: !!headers?.encryptedHeader && !!orgIdState && !!isMultiStagePool,
    refetchOnWindowFocus: false,
  });

  const pageOptionsName = useMemo(() => {
    let arr = gameDetails.branchPagesInfo ? Object.keys(gameDetails?.branchPagesInfo) : [];

    if (!arr.length) return [];
    if (arr.length > 2) {
      return ["Initial Rounds", "Middle Rounds", "Final Rounds"];
    } else return ["Initial Rounds", "Final Rounds"];
  }, [gameDetails?.branchPagesInfo]);

  const pageOptions = useMemo(
    () =>
      gameDetails.branchPagesInfo
        ? [
            ...Object.keys(gameDetails?.branchPagesInfo).map((val, idx) => ({
              name: pageOptionsName[idx],
              value: parseInt(val),
            })),
          ]
        : [],
    [gameDetails.branchPagesInfo],
  );

  const branchOptions = useMemo(
    () =>
      gameDetails.branchPagesInfo
        ? [...Array(gameDetails.branchPagesInfo[selectedPage]).keys()].map(val => {
            return {
              name: parseInt(val) + 1, // Need to add 1 because arrays are 0 based so we are getting a 0 value in the dropdown but our data starts from 1
              value: parseInt(val) + 1,
            };
          })
        : [],
    [gameDetails.branchPagesInfo, selectedPage],
  );

  const roundOptions = useMemo(() => {
    if (roundsQuantity && roundsQuantity > 0) {
      return [...Array(roundsQuantity).keys()]?.map(val => {
        return {
          name: parseInt(val) + 1, // Need to add 1 because arrays are 0 based so we are getting a 0 value in the dropdown but our data starts from 1
          value: parseInt(val) + 1,
        };
      });
    } else return undefined;
  }, [roundsQuantity]);

  const isDataLoading = useMemo(() => {
    return (
      isLoadingPlayers ||
      isFetchingPoolData ||
      isCreateBracketsLoading ||
      isFetchingPlayers ||
      (isMultiStagePool && (isLoadingPlayersStageBracketsData || isFetchingPlayersStageBracketsData))
    );
  }, [
    isLoadingPlayers,
    isFetchingPoolData,
    isCreateBracketsLoading,
    isFetchingPlayers,
    isLoadingPlayersStageBracketsData,
    isFetchingPlayersStageBracketsData,
  ]);

  //Creating swap function here instead of inside the swap modal, because it will be easier to access all required props here as well as to invalidate the players query on success
  const { mutate: swapBracketPlayersMutation, isLoading: mutateSwapIsLoading } = useMutation({
    mutationFn: args => {
      const { playerToSwap = {}, swapWith = {} } = args;
      const firstPlayerAndMatchData = {
        id: playerToSwap.aTag,
        nick: playerToSwap.nickname,
        branch: playerToSwap.branch,
        match: playerToSwap.match,
        position: playerToSwap.position,
      };
      const secondPlayerData = {
        id: swapWith.aTag,
        nick: swapWith.nickname,
      };
      return swapBracketPlayers(
        {
          organizationId: orgIdState,
          poolId: Number(poolId),
          playerToSwap: firstPlayerAndMatchData,
          swapWith: secondPlayerData,
          stageId: stageId || 0,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: () => {
      // NOTE: Check for stage here

      queryClient.invalidateQueries({
        queryKey: isMultiStagePool
          ? ["stage_brackets", orgIdState, poolId, selectedPage, selectedBranch, selectedRound, roundsQuantity]
          : ["players", orgIdState, poolId, selectedPage, selectedBranch, selectedRound, roundsQuantity],
      });
      notifySuccess("Swap successful");
      setOpenSwapModal(false);
    },
  });

  const { mutate: updateRoundMode, isLoading: isLoadingUpdateRoundMode } = useMutation({
    mutationFn: variables => {
      return executePoolAction(
        {
          organizationId: orgIdState,
          poolId: poolId,
          action: "update_round_type",
          data: {
            branchId: selectedBranch,
            pageId: selectedPage,
            roundIx: selectedRound,
            roundMode: variables.roundMode,
            ...(stageId && isMultiStagePool && { stageId }),
          },
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: isMultiStagePool
          ? ["stage_brackets", orgIdState, poolId, selectedPage, selectedBranch, selectedRound, roundsQuantity]
          : ["players", orgIdState, poolId, selectedPage, selectedBranch, selectedRound, roundsQuantity],
      });
      notifySuccess("Round Mode Updated");
    },
  });

  const { mutate: mutateUpdateRoundDate, isLoading: isLoadingUpdateRoundTime } = useMutation({
    mutationFn: variables => {
      return executePoolAction(
        {
          organizationId: orgIdState,
          poolId: poolId,
          stageId: stageId,
          action: "set_round_dates",
          data: {
            pageId: selectedPage,
            branchId: selectedBranch,
            roundIx: selectedRound,
            startDate: variables.startDate,
            endDate: variables.endDate,
          },
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: isMultiStagePool
          ? ["stage_brackets", orgIdState, poolId, selectedPage, selectedBranch, selectedRound, roundsQuantity]
          : ["players", orgIdState, poolId, selectedPage, selectedBranch, selectedRound, roundsQuantity],
      });
      notifySuccess("Round Mode Updated");
    },
  });

  const bracketDetail = isMultiStagePool ? stageBracketsData?.bracketDetail : regularPoolBracketsData?.bracketDetail;

  const [startDateInput, setStartDateInput] = useState(null);
  const [endDateInput, setEndDateInput] = useState(null);

  const roundStartDate = moment(startDateInput).format("DD MMM, HH:MM ");
  const roundEndDate = moment(endDateInput).format("DD MMM, HH:MM ");

  useEffect(() => {
    // NOTE: bracketDetails can be an array if the roundNumber in the reuqest is set to 0. This request is made to get the total number of rounds of a page and branch
    if (bracketDetail && Array.isArray(bracketDetail) && bracketDetail?.length > 0) {
      setRoundsQuantity(Object.keys(bracketDetail[0]?.rounds)?.length);
    }
    if (bracketDetail) {
      setStartDateInput(bracketDetail.startDate);
      setEndDateInput(bracketDetail.endDate);
    }
  }, [bracketDetail]);

  return (
    <BracketsDataContext.Provider
      value={{
        openSwapModal,
        setOpenSwapModal,
        branchOptions,
        selectedBranch,
        playerToSwap,
        setPlayerToSwap,
        selectedRound,
        isMultiStagePool,
      }}
    >
      <div>
        {gameDetails?.tournamentPlayersQty && gameDetails.tournamentPlayersQty > 0 ? (
          <div>
            <S.DataHeader>
              <S.DataHeaderText>Brackets</S.DataHeaderText>
              {
                <S.DataHeaderPlayerCount>
                  {regularPoolBracketsData?.leaders?.length}{" "}
                  {regularPoolBracketsData?.leaders?.length === 1 ? "Player" : "Players"}
                </S.DataHeaderPlayerCount>
              }
              <RoundSelectorDropdown
                roundMode={bracketDetail?.roundMode}
                updateRoundMode={updateRoundMode}
                isLoadingUpdateRoundMode={isLoadingUpdateRoundMode}
                isRefetchingData={isFetchingPlayers || isFetchingPlayersStageBracketsData}
              />
            </S.DataHeader>
            <S.DropdownSelectorContainer>
              <S.DropdownSelectorWrapper>
                <S.DropdownSelectorText>Page</S.DropdownSelectorText>
                <Dropdown
                  className="brackets-page-dropdown"
                  disabled={pageOptions?.length <= 1}
                  options={pageOptions}
                  placeholder="Select a Page"
                  onChange={val => {
                    setSelectedRound(1);
                    setRoundsQuantity(0);
                    setSelectedBranch(1);
                    setSelectedPage(val);
                  }}
                  value={pageOptions?.length > 1 ? selectedPage : "-"}
                />
              </S.DropdownSelectorWrapper>
              <S.DropdownSelectorWrapper>
                <S.DropdownSelectorText>Branch</S.DropdownSelectorText>
                <Dropdown
                  className="brackets-page-dropdown"
                  disabled={branchOptions?.length <= 1}
                  options={branchOptions}
                  placeholder="Select a Bracket"
                  onChange={val => {
                    setSelectedBranch(val);
                  }}
                  value={branchOptions?.length > 1 ? selectedBranch : "-"}
                />
              </S.DropdownSelectorWrapper>
              {roundOptions && (
                <>
                  <S.DropdownSelectorWrapper>
                    <S.DropdownSelectorText>Round</S.DropdownSelectorText>
                    <Dropdown
                      className="brackets-page-dropdown"
                      options={roundOptions}
                      placeholder="Select a Bracket"
                      onChange={setSelectedRound}
                    />
                  </S.DropdownSelectorWrapper>
                  <S.DropdownSelectorWrapper>
                    <S.DropdownSelectorText>Round Date</S.DropdownSelectorText>
                    <S.DateSelector onClick={toggleRoundTimeModal}>
                      <Calendar />
                      <S.DateText>
                        {roundStartDate} - {roundEndDate}
                      </S.DateText>
                    </S.DateSelector>
                    <RoundDateSwitchModal
                      showModal={showRoundTimeModal}
                      toggleModal={toggleRoundTimeModal}
                      bracketDetail={bracketDetail}
                      mutateUpdateRoundDate={mutateUpdateRoundDate}
                      isLoadingUpdateRoundTime={isLoadingUpdateRoundTime}
                      startDateInput={startDateInput}
                      setStartDateInput={setStartDateInput}
                      setEndDateInput={setEndDateInput}
                      endDateInput={endDateInput}
                    />
                  </S.DropdownSelectorWrapper>
                </>
              )}
            </S.DropdownSelectorContainer>

            {/* NOTE: Checking that bracketDetail is not an array. Further details mentioned in a useEffect above */}
            {bracketDetail &&
            isNonEmptyObject(bracketDetail) &&
            !isFetchingPlayers &&
            !isFetchingPlayersStageBracketsData ? (
              <>
                <S.BracketDataContainer>
                  <TableHeader />
                  <S.BracketWrapper>
                    {bracketDetail?.matches &&
                      Object.entries(bracketDetail?.matches)?.map(([indx, matchOb]) => (
                        <S.BracketRowContainer>
                          <MatchContainer
                            match={matchOb}
                            poolId={poolId}
                            poolStatus={poolStatus}
                            pageId={selectedPage}
                            stageId={stageId}
                            branchId={selectedBranch}
                            organizationId={orgIdState}
                            roundId={roundsQuantity ? selectedRound : 0}
                            roundsQuantity={roundsQuantity ? roundsQuantity : 0}
                            matchNumber={indx}
                            updateSelectedChatData={updateSelectedChatData}
                            finalPageRound={
                              selectedPage === pageOptions?.[pageOptions.length - 1]?.value &&
                              selectedRound === roundOptions?.[roundOptions.length - 1]?.value
                            }
                            joinMode={joinMode}
                            isMultiStage={isMultiStagePool}
                          />
                        </S.BracketRowContainer>
                      ))}
                  </S.BracketWrapper>
                </S.BracketDataContainer>
              </>
            ) : isDataLoading || Array.isArray(bracketDetail) ? (
              <>
                <TableHeader />
                <LoadingComponent />
              </>
            ) : (
              <div>No Bracket Detail Found</div>
            )}
          </div>
        ) : (
          <>
            {isDataLoading ? (
              <>
                <TableHeader />
                <LoadingComponent />
              </>
            ) : (
              <S.CreateBracketContainer>
                <S.CreateBracketText>
                  Create brackets <S.CreateBracketLink onClick={toggleBracketCreateModal}>here</S.CreateBracketLink>
                </S.CreateBracketText>
              </S.CreateBracketContainer>
            )}
          </>
        )}
        <CreateBracketModal
          toggleModal={toggleBracketCreateModal}
          showModal={showBracketCreateModal}
          setIsCreateBracketsLoading={setIsCreateBracketsLoading}
          style={{ background: "#3f2faa" }}
        />

        <SwapModal swapBracketPlayersMutation={swapBracketPlayersMutation} isLoadingSwap={mutateSwapIsLoading} />
      </div>
    </BracketsDataContext.Provider>
  );
};

export default Brackets;
