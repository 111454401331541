import React, { useContext, useEffect, useState } from "react";

import * as S from "../styles";
import { BracketRow } from "../BracketRow/BracketRow";
import { movePlayerRound as movePlayerRoundApiCall } from "../../../../api/pool";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useLogin } from "../../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { notifyError, notifySuccess } from "../../../common/ToastComponent";
import { TournamentContext } from "../../../../providers/TournamentContext";
import { isNonEmptyObject } from "../../../../utilities";
import Container from "../../../common/Container/Container";
import { MessageIcon } from "../../../../assets/svg";
import { IconButton } from "../../../common/Button";

const MatchContainer = ({
  match,
  pageId,
  stageId,
  branchId,
  roundId,
  matchNumber,
  roundsQuantity,
  finalPageRound,
  poolStatus,
  joinMode,
  isMultiStage,
  updateSelectedChatData,
}) => {
  const [selectedUserId, setSelectedUserId] = useState("");
  const { poolId } = useParams();
  const { orgIdState } = useLogin();
  const queryClient = useQueryClient();

  const { setExtraReportData, extraReportData, setShowReportModal } = useContext(TournamentContext);

  const headers = useGetSecHeaders();

  function handleBracketsReportAction(userIdInput) {
    setExtraReportData({
      match,
      pageId,
      roundId,
      branchId,
      matchId: matchNumber,
      userId: userIdInput,
      roundsQuantity,
    });
    setShowReportModal(true);
    setSelectedUserId(userIdInput);
  }

  useEffect(() => {
    // We need to update the match that we have sent down to components using context
    if (extraReportData?.match && isNonEmptyObject(extraReportData?.match) && extraReportData?.match.id === match?.id) {
      setExtraReportData({
        ...extraReportData,
        match: match,
      });
    }
  }, [match]);
  const { mutate: movePlayerRound, isLoading: isLoadingMovePlayer } = useMutation({
    mutationFn: winnerPos => {
      return movePlayerRoundApiCall(
        {
          organizationId: orgIdState,
          poolId,
          stageId: isMultiStage ? stageId : 0,
          branchId,
          matchId: matchNumber,
          pageId,
          roundId,
          winnerPosition: winnerPos,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: responseData => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      } else {
        notifySuccess("Player moved successfully");
        // NOTE: Check for stage here
        queryClient.invalidateQueries({
          queryKey: ["players", orgIdState, poolId, pageId, branchId, roundId, roundsQuantity],
        });
      }
    },
  });

  const sharedProps = {
    status: match.status,
    poolStatus: poolStatus,
    movePlayerRound,
    isLoadingMovePlayer,
    handleBracketsReportAction,
    finalPageRound,
    matchNumber,
    joinMode,
  };

  let p1Wins = [];
  let p2Wins = [];
  match?.gamesData?.forEach(item => {
    if (item.winner === 1) {
      p1Wins.push("W");
      p2Wins.push("L");
    } else {
      p1Wins.push("L");
      p2Wins.push("W");
    }
  });

  function handleChat() {
    // The original implementation of updateSelectedChatData is expecting room data. We do not have access to room data in this component, but all the REQUIRED properties for the join room function is available in this component. So we are creating an object with the required structure and passing it to the function 
    const obj = {
      id: `${poolId}_${match.id}`,
      roomId: `${poolId}_${match.id}`,
      cmdRoomId: poolId?.toString(),
      extProps: {
        matchId: match.id,
        poolId: Number(poolId),
      },
    };

    updateSelectedChatData(obj);
  }
  return (
    <Container flex columnGap="4px">
      <Container flex rowGap="4px" flexDirection="column" width="100%">
        <BracketRow data={match.p1Data} results={p1Wins} playerPos={1} {...sharedProps} />
        <BracketRow data={match.p2Data} results={p2Wins} playerPos={2} {...sharedProps} />
      </Container>

      <S.ChatIconContainer>
        <IconButton onClick={handleChat}>
          <MessageIcon />
        </IconButton>
      </S.ChatIconContainer>
    </Container>
  );
};

export default MatchContainer;
