import styled from "styled-components";

export const CreateBracketContainer = styled.div`
  height: 364px;
  border-radius: 6px;
  border: 1px solid rgba(114, 101, 200, 0.3);
  background: #3f2faa;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreateBracketText = styled.span`
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 325;
  line-height: 36px;
`;

export const CreateBracketLink = styled.a`
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 325;
  line-height: 36px;
  color: #be7eff;
  text-decoration: underline;
  cursor: pointer;
`;

export const DataHeader = styled.div`
  display: flex;
  gap: 10px;
`;

export const DataHeaderText = styled.h4`
  font-size: 24px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin-top: 0;
`;

export const DataHeaderPlayerCount = styled.span`
  border-radius: 14.5px;
  background: #342788;
  width: 93px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a498ed;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  text-transform: capitalize;
`;

export const DropdownSelectorContainer = styled.div`
  display: flex;
  gap: 25px;
  margin-bottom: 68px;
  @media (max-width: 375px) {
    /* flex-wrap: wrap; */
    gap: 15px;
  }
`;

export const DropdownSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DropdownSelectorText = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const DateText = styled.span`
  font-size: 12px;
  font-weight: 325;
  line-height: 14.4px;
`;

export const DateSelector = styled.div`
  background-color: #3f2faa;
  height: 37px;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  gap: 10px;

  :hover {
    cursor: pointer;
  }
`;

export const BracketDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 425px) {
    /* flex-wrap: wrap; */
    overflow: scroll;
  }
`;

export const BracketWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const BracketDataHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr repeat(2, 2fr);
  height: 32px;
  p:last-child {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 468px) {
    width: 768px;
    grid-template-columns: 1fr 4fr repeat(3, 2fr);
  }
`;

export const BracketDataHeaderText = styled.p`
  margin: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  color: #a498ed;
`;

export const BracketRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const LoadingRowsContainer = styled.div`
  margin-bottom: 30px;
`;

export const ChatIconContainer = styled.div`
  width: 80px;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #342788;
`