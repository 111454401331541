import React, { useCallback, useState } from "react";
import * as S from "./styles";
import Chat from ".";
import Container from "../../../../../components/common/Container/Container";
const Row = ({ rowData = {}, updateSelectedChatData }) => {
  const { usersConnected = [] } = rowData;

  const players = usersConnected.filter(user => user.type !== "ADMIN");

  return (
    <S.ChatroomSectionRow onClick={() => updateSelectedChatData(rowData)}>
      {players[0]?.userNick}

      {players[1] ? (
        <>
          <span>vs</span> {players[1].userNick}{" "}
        </>
      ) : null}
    </S.ChatroomSectionRow>
  );
};
const ChatroomSection = ({ chatRequests = [], poolData = {}, updateSelectedChatData = {} }) => {
  if (chatRequests.length < 1) {
    return (
      <>
        <Container flex alightItems="center" justifyContent="center" marginTop="100px">
          You do not have any pending chat requests
        </Container>
      </>
    );
  }
  return (
    <>
      <S.ChatroomSectionContainer>
        {chatRequests?.map(rowData => (
          <Row rowData={rowData} updateSelectedChatData={updateSelectedChatData} />
        ))}
      </S.ChatroomSectionContainer>
    </>
  );
};

export default ChatroomSection;
