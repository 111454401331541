import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  min-height: 48px;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr repeat(2, 2fr); //1fr 6fr repeat(3, 2fr)
  background-color: #342788;
  align-items: center;
  div:last-child {
    justify-content: center;
  }

  &:nth-child(odd) {
    border-top-left-radius: 10px;
    /* border-top-right-radius: 10px; */
  }

  &:nth-child(even) {
    border-bottom-left-radius: 10px;
    /* border-bottom-right-radius: 10px; */
  }

  // HERE
  @media (max-width: 468px) {
    width: 768px;
    grid-template-columns: 1fr 4fr repeat(3, 2fr);
  }
`;

export const IdWrapper = styled.div`
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4e3cca;

  border-radius: inherit;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const IdText = styled.span`
  color: #877add;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
`;

export const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  /* max-width: 100%;
  overflow: hidden; */

  &:hover {
    cursor: ${props => (props.joinMode === "TEAM" ? "pointer" : "default")};
  }
`;

export const ResultsWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  width: 80%;
`;
export const PlayerName = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  /* max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const BranchText = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const ScoreWrapper = styled.div`
  background: ${props => (props.isWinner ? "#46AD3D" : "#ED6140")};
  width: 26px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #b2fa4f;
`;

export const StatusText = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const ActionButton = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;
