import React, { useContext } from "react";
import { SocketAuthContext } from "../../../../providers/SocketAuthProvider";
import * as S from "../../styles";
import { POOL_MODES, POOL_TYPES } from "../../../../constants";
import { useLogin } from "../../../../providers/Loginprovider";
import { InvitationalCode } from "../../../../components/views/tournamentDetail/InvitationalCode";
import BracketsSection from "./BracketsSection";
import PlayersSection from "./PlayersSection";
import LeaderboardSection from "./LeaderboardSection";
import RulesSection from "./RulesSection";
import ChangesRequiredSection from "./ChangesRequiredSection";
import ContactSection from "./ContactInfoSection";
import ChatroomSection from "./Chat/ChatroomSection";

const Sections = props => {
  const {
    poolData = {},
    activeSection = "",
    isFetchingPoolData = false,
    selectedStage = {},
    stageInfo = {},
    isLoadingStageInfo = false,
    chatRequests = [],
    updateSelectedChatData = {},
  } = props; // Destructuring props with default values to avoid error

  const shouldShowBrackets = activeSection === "Brackets";
  const shouldShowInvitationalCodeSection = activeSection === "Invitational Codes";
  const shouldShowLeaderboardSection = activeSection === "Leaderboard";
  const shouldShowRulesSection = activeSection === "Info";
  const shouldShowChangesRequiredSection = activeSection === "Changes Required";
  const shouldShowContactInfoSection = activeSection === "Contact Info";
  const shouldShowChatroomSection = activeSection === "Chatroom"; // Not checking isSocketAuthenticated and chatroomsResponse because the Chatoom tag button will only be visible after all those checks. So if the button is visible then all these checks were already passed. Check TagButton component for more information

  const { orgIdState } = useLogin();

  const poolId = poolData.id;
  const isMultiStagePool = poolData.poolMode === POOL_MODES.MULTI_STAGE;
  const isRoundRobin = poolData.poolMode === POOL_MODES.ROUND_ROBIN;

  return (
    <>
      {shouldShowBrackets ? (
        <BracketsSection
          {...{ poolData, selectedStage, isFetchingPoolData, joinMode: poolData?.joinMode, updateSelectedChatData }}
        />
      ) : shouldShowLeaderboardSection ? (
        <LeaderboardSection
          {...{ isMultiStagePool, isRoundRobin, selectedStage, stageInfo, isLoadingStageInfo, poolId, poolData }}
        />
      ) : shouldShowInvitationalCodeSection ? (
        <InvitationalCode gameDetails={poolData.gameDetails} />
      ) : shouldShowRulesSection ? (
        <RulesSection poolRules={isMultiStagePool ? selectedStage.rules : poolData.rules} organizationId={orgIdState} />
      ) : shouldShowChangesRequiredSection ? (
        <ChangesRequiredSection messages={poolData?.adminMsg || []} poolStatus={poolData.poolStatus} />
      ) : shouldShowContactInfoSection ? (
        <ContactSection organizerInfo={poolData?.organizerInfo} />
      ) : shouldShowChatroomSection ? (
        <ChatroomSection
          chatRequests={chatRequests}
          poolData={poolData}
          updateSelectedChatData={updateSelectedChatData}
        />
      ) : (
        <S.DataContainer>
          <PlayersSection {...{ isMultiStagePool, poolData, selectedStage }} />
        </S.DataContainer>
      )}
    </>
  );
};

export default Sections;
